import React from 'react'
import {observer} from 'mobx-react'
import './style.scss'
import {getRandomKey} from '../../Helpers/Default'

export const DefaultBadge = observer((props: any) => {
  let badge = ''
  let type = ''
  let style = ''
  let size = ''
  let className = ''
  let color: string = 'primary'
  if (props.type) {
    type = props.type + '-'
  }

  if (props.style) {
    style = props.style + '-'
  }

  if (props.color) {
    color = props.color
  }

  if (props.size) {
    size = 'badge-' + props.size
  }

  if (props.className) {
    className = props.className
  }

  badge = 'badge-' + type + style + color

  return (
    <span
      key={props.key || getRandomKey()}
      style={props.customStyle ? props.customStyle : {}}
      className={`badge ${badge} fs-8 fw-bold-customer ${className} ${size}`}
    >
      {props.children}
    </span>
  )
})
