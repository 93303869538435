import {action, makeAutoObservable} from 'mobx'
import {apiRequest, getHeaders} from '../../Helpers/Request'
import {REQ_TYPE} from '../../Constants/Request'
import {IsDemo} from '../../Helpers/Demo'

class DiscussionStore {
  data: any[] = []
  openCount = 0
  isCreating: boolean = false
  isUpdating: boolean = false
  isDeleting: boolean = false
  isLoading: boolean = false
  isCommenting: boolean = false
  isFinishing: boolean = false

  constructor() {
    makeAutoObservable(this, {
      setValue: action,
    })
  }
  setValue(name: string, value: any) {
    // @ts-ignore
    this[name] = value
  }

  async remove(id: string) {
    return true
  }
  async create(data: any, attachments: any[]) {
    if (IsDemo()) {
      return false
    }
    if (this.isCreating) {
      return null
    }

    this.setValue('isCreating', true)

    const formData = new FormData()
    formData.append('data', JSON.stringify(data))

    attachments.forEach((attachment: any, index: any) => {
      formData.append(`files[${index}]`, attachment.file)
      formData.append(`names[${index}]`, attachment.name)
      formData.append(`extends[${index}]`, attachment.extend)
    })

    const res = await apiRequest(
      '/api/v2/discussion',
      REQ_TYPE.post,
      // @ts-ignore
      formData,
      // @ts-ignore
      getHeaders(null, {'Content-Type': 'multipart/form-data'}),
      this,
      ''
    )

    this.setValue('isCreating', false)

    return res.status === 200
  }
  async update(data: any) {}

  async addComment(id: string, data: any, attachments: any[] = []) {
    if (IsDemo()) {
      return false
    }
    if (this.isCreating) {
      return null
    }

    this.setValue('isCreating', true)

    const formData = new FormData()
    formData.append('data', data)

    attachments.forEach((attachment: any, index: any) => {
      formData.append(`files[${index}]`, attachment.file)
      formData.append(`names[${index}]`, attachment.name)
      formData.append(`extends[${index}]`, attachment.extend)
    })

    const res = await apiRequest(
      `/api/v2/discussion/${id}/comment`,
      REQ_TYPE.post,
      // @ts-ignore
      formData,
      // @ts-ignore
      getHeaders(null, {'Content-Type': 'multipart/form-data'}),
      this,
      ''
    )

    this.setValue('isCreating', false)

    return res.status === 200
  }
  async get(id: string) {
    this.setValue('isLoading', true)
    const res = await apiRequest(
      `/api/v2/discussion/${id}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )
    this.setValue('isLoading', false)
    return res.data
  }
  async getAll(tab: any) {
    this.setValue('isLoading', true)
    const res = await apiRequest(
      `/api/v2/discussion${tab ? `?tab=${tab}` : ''}`,
      REQ_TYPE.get,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    if (res && res.data) {
      this.setValue('data', res.data.data)
      this.setValue('openCount', res.data.count)
    }

    this.setValue('isLoading', false)
  }

  async finishDiscussion(id: string) {
    if (IsDemo()) {
      return false
    }
    if (this.isFinishing) {
      return null
    }

    this.setValue('isFinishing', true)

    const res = await apiRequest(
      `/api/v2/discussion/${id}/finish`,
      REQ_TYPE.post,
      null,
      // @ts-ignore
      getHeaders(),
      this,
      ''
    )

    this.setValue('isFinishing', false)

    return res.status === 200
  }
}
export default new DiscussionStore()
