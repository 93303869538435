import React, {useEffect, useRef} from 'react'
import {observer} from 'mobx-react'

import './style.scss'
import {InputLabel} from '../InputLabel'
import {isEmpty} from 'lodash'
import {getTranslation, removeHtmlTags} from '../../Helpers/Default'
import {useIntl} from 'react-intl'
import EmptyText from '../EmptyText'
import ReactQuill, {Quill} from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const Link = Quill.import('formats/link')
class CustomLink extends Link {
  static create(value: any) {
    const node = super.create(value)
    node.setAttribute('target', '_blank')
    return node
  }
}

const TextEditor = observer((props: any) => {
  const intl = useIntl()
  const quillRef: any = useRef(null)
  Quill.register(CustomLink, true)

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor()
      let isUpdating = false
      const toolbar = document.querySelector('.ql-toolbar')
      const buttons = {
        bold: toolbar?.querySelector('.ql-bold'),
        italic: toolbar?.querySelector('.ql-italic'),
        underline: toolbar?.querySelector('.ql-underline'),
        link: toolbar?.querySelector('.ql-link'),
      }

      if (buttons.bold) buttons.bold.setAttribute('aria-label', 'aaa')

      quill.on('text-change', (delta: any, oldDelta: any, source: any) => {
        if (isUpdating) return
        isUpdating = true

        const editorText = quill.getText()
        const cursorPosition = quill.getSelection()?.index || 0

        let currentIndex = 0

        delta.ops.forEach((op: any) => {
          if (op.retain) {
            currentIndex += op.retain
          }

          if (op.insert && typeof op.insert === 'string') {
            const urlRegex = /(https?:\/\/[^\s]+)/g
            const match = op.insert.match(urlRegex)

            if (match) {
              const rangeStart = currentIndex
              const rangeLength = match[0].length

              quill.formatText(rangeStart, rangeLength, 'link', match[0])
            }

            currentIndex += op.insert.length
          }
        })

        if (source === 'user') {
          const textBeforeCursor = editorText.slice(0, cursorPosition)
          const urlRegex = /(https?:\/\/[^\s]+)/g

          const matches = [...textBeforeCursor.matchAll(urlRegex)]
          if (matches.length > 0) {
            const lastMatch = matches[matches.length - 1]
            const urlStartIndex = textBeforeCursor.lastIndexOf(lastMatch[0])
            const urlLength = lastMatch[0].length

            const formats = quill.getFormat(urlStartIndex, urlLength)
            if (!formats.link) {
              quill.formatText(urlStartIndex, urlLength, 'link', lastMatch[0])
            }
          }
        }

        isUpdating = false
      })
    }
  }, [])

  const handleLinkInsert = (e: any) => {
    console.log('--', e)
    // const selection = window.getSelection().toString()
    const url = prompt('Zadejte odkaz:', 'https://')

    // if (url) {
    //   const quill = document.querySelector('.ql-editor')
    //   document.execCommand(
    //     'insertHTML',
    //     false,
    //     `<a href="${url}" target="_blank">${selection || 'Odkaz'}</a>`
    //   )
    // }
  }

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike', 'link'],
    [{list: 'ordered'}, {list: 'bullet'}, {list: 'check'}],
    [{header: [1, 2, 3, 4, 5, 6, false]}],
    [{color: []}, {background: []}],
    [{align: []}],
    ['clean'],
  ]

  const customToolbar = () => {
    // Přidáme české překlady přímo do toolbaru
    const toolbar = document.querySelector('.ql-toolbar')
    if (toolbar) {
      const headerSelect = toolbar.querySelector('.ql-header')
      if (headerSelect) {
        const options: any = headerSelect.querySelectorAll('.ql-picker-item')
        if (options && options[0]) {
          options[0].setAttribute('data-translation', intl.formatMessage({id: 'heading1'}))
          options[1].setAttribute('data-translation', intl.formatMessage({id: 'heading2'}))
          options[2].setAttribute('data-translation', intl.formatMessage({id: 'heading3'}))
          options[3].setAttribute('data-translation', intl.formatMessage({id: 'heading4'}))
          options[4].setAttribute('data-translation', intl.formatMessage({id: 'heading5'}))
          options[5].setAttribute('data-translation', intl.formatMessage({id: 'heading6'}))
          options[6].setAttribute('data-translation', intl.formatMessage({id: 'normal'}))
        }
      }
    }
  }

  React.useEffect(() => {
    setTimeout(() => {
      customToolbar()
    }, 1000)
  }, [])

  let labelClass = ['fw-bold-custom']
  if (props.required) {
    labelClass.push('required')
  }

  const handleChange = (e: any) => {
    props.action({
      target: {
        name: props.name,
        value: e,
      },
    })
  }

  if (!props.isEdit) {
    return (
      <div className='pt-4 fs-6'>
        {isEmpty(removeHtmlTags(props.value)) ? (
          <EmptyText
            text={props.emptyText ? props.emptyText : getTranslation('DESCRIPTION.EMPTY', intl)}
          />
        ) : (
          <div dangerouslySetInnerHTML={{__html: props.value}}></div>
        )}
      </div>
    )
  }

  return (
    <div className={`${props.className ?? props.className}`}>
      {props.label ? <InputLabel className={labelClass.join(' ')}>{props.label}</InputLabel> : null}
      <ReactQuill
        ref={quillRef}
        theme='snow'
        value={props.value}
        onChange={handleChange}
        modules={{
          toolbar: props.customToolbar ? props.customToolbar : toolbarOptions,
        }}
      />
      {props.formik && props.formik.touched[props.name] && props.formik.errors[props.name] && (
        <div className='fv-plugins-message-container invalid-feedback d-block'>
          <div className='fv-help-block'>{props.formik.errors[props.name]}</div>
        </div>
      )}
    </div>
  )
})

export default TextEditor
